<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";
import { required } from "vuelidate/lib/validators";

export default {
  locales: {
    pt: {},
    es: {},
  },
  components: { Layout },
  data() {
    return {
      loading: false,
      success: false,

      name: "",
      cellphone: "",
      description: "",

      alert: {
        type: "",
        message: "",
      },
    };
  },
  validations: {
    name: { required },
    cellphone: { required },
    description: { required },
  },
  methods: {
    addLead() {
      this.loading = true;
      this.$v.$touch();

      if (this.name && this.cellphone && this.description) {
        api
          .post("crm/leads", {
            name: this.name,
            cellphone: this.cellphone,
            description: this.description,
          })
          .then((response) => {
            if (response.data.status == "success") {
              this.success = true;
              this.name = "";
              this.cellphone = "";
              this.description = "";
              this.$v.$reset();

              this.alert.type = "alert-success";
              this.alert.message = response.data.message;
            } else {
              this.password = "";
              this.$v.$reset();

              this.alert.type = "alert-danger";
              this.alert.message = response.data.message;
            }

            this.loading = false;
          });
      }
    },
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">{{ t("Cadastrar") }}</h4>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xl-7">
        <div class="card">
          <div class="card-body p-4">
            <div v-if="success" class="text-center-sm">
              O cliente foi cadastrado com sucesso.
            </div>
            <b-form v-else class="p-3" @submit.prevent="addLead">
              <div
                v-if="alert.message"
                :class="'alert ' + alert.type"
              >
                {{ t(alert.message) }}
              </div>
              <b-form-group id="name" :label="t('Nome')" label-for="name">
                <b-form-input
                  id="name"
                  v-model="name"
                  type="text"
                  inputmode="decimal"
                  :class="{ 'is-invalid': $v.name.$error }"
                ></b-form-input>
                <div v-if="$v.name.$error" class="invalid-feedback">
                  <span v-if="!$v.name.required">O nome é obrigatório.</span>
                </div>
              </b-form-group>
              <b-form-group
                id="cellphone"
                :label="t('Celular')"
                label-for="cellphone"
              >
                <b-form-input
                  id="cellphone"
                  v-model="cellphone"
                  type="text"
                  inputmode="decimal"
                  :class="{ 'is-invalid': $v.cellphone.$error }"
                ></b-form-input>
                <div v-if="$v.cellphone.$error" class="invalid-feedback">
                  <span v-if="!$v.cellphone.required"
                    >O celular é obrigatório.</span
                  >
                </div>
              </b-form-group>
              <b-form-group
                id="description"
                :label="t('Veículo de Interesse')"
                label-for="description"
              >
                <b-form-input
                  id="description"
                  v-model="description"
                  type="text"
                  inputmode="decimal"
                  :class="{ 'is-invalid': $v.description.$error }"
                ></b-form-input>
                <div v-if="$v.description.$error" class="invalid-feedback">
                  <span v-if="!$v.description.required"
                    >O veículo de interesse é obrigatório.</span
                  >
                </div>
              </b-form-group>
              <div class="mt-4">
                <b-button
                  :disabled="loading == true"
                  type="submit"
                  variant="default"
                >
                  Cadastrar
                  <b-spinner
                    v-if="loading"
                    small
                    class="ml-2 align-middle"
                    variant="dark"
                    role="status"
                  ></b-spinner>
                </b-button>
              </div>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
